import React, { getGlobal, useState, useEffect } from 'reactn';
import moment from 'moment';
import 'moment/locale/zh-hk';
import 'moment/locale/en-gb';
import { useSignUpLink } from '../../../hooks/useBoutirAdmin';

import fetchAllData from '../../../components/fetchData';
import PageWrapper from '../../../components/pagewrapper';
import { languageObj } from '../../../src/language';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ScrollTrigger from 'react-scroll-trigger';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const onEnterViewport = (p, e) => {
  let current = e.element.querySelector('.slick-current');
  if (current) {
    current.querySelector('.case .video video').play();
    current.querySelector('.case .video .play-button').classList =
      'play-button fade-out';
  }
};

const onExitViewport = (p, e) => {
  let current = e.element.querySelector('.slick-current');
  if (current) {
    current.querySelector('.case .video video').pause();
    current.querySelector('.case .video .play-button').classList =
      'play-button';
  }
};

const onSwipe = (o, n) => {
  document.querySelectorAll('.case .video video')[o].pause();
  document.querySelectorAll('.case .video .play-button')[o].classList =
    'play-button';
  document.querySelectorAll('.case .video video')[n].play();
  document.querySelectorAll('.case .video .play-button')[n].classList =
    'play-button fade-out';
};

const ClickVideo = (e) => {
  let video = '';
  if (e.target.className.indexOf('play-button') !== -1) {
    video = e.target.parentElement.children[0];
  } else {
    video = e.target;
  }
  if (video.paused) {
    video.play();
    video.parentElement.children[1].classList = 'play-button fade-out';
  } else {
    video.pause();
    video.parentElement.children[1].classList = 'play-button';
  }
};

const Homepage = ({ pageData, resData }) => {
  const signUpLink = useSignUpLink();

  const { data } = pageData['pageData'];
  const { serverReq } = getGlobal();
  const translate = data.length >= 3 ? data[2].data.attributes : false;
  const homepagedata = data.length >= 4 ? data[3] : false;

  const resourcelistdata =
    resData['pageData']['data'].length >= 4
      ? resData['pageData']['data'][3]
      : false;

  // Module HomeBanner
  const HomeBanner = ({
    video,
    mobileVideo,
    title,
    description,
    merchantNumber,
    merchantLabel,
    logos,
  }) => {
    const slidersetting = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 1500,
      cssEase: 'linear',
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    };

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth < 992);
      }
    }, []);

    return (
      <div
        className={`hero-banner ${
          title || description ? 'bg-image ' : ''
        }mb-4 mb-md-5`}
      >
        <video
          src={video}
          className={`d-none d-md-block`}
          playsInline
          autoPlay
          muted
          loop
        />
        <video
          src={mobileVideo}
          className={`d-block d-md-none`}
          playsInline
          autoPlay
          muted
          loop
        ></video>
        <div className={`hero-banner-info w-100 text-center`}>
          <Container fluid={`lg`}>
            <h1 className={`title mb-3`}>{title}</h1>
            <h2 className={`description`}>{description}</h2>
            <a
              className={`d-inline-block btn btn-green mt-4 py-3`}
              href={isMobile ? `http://boutir.com/app` : signUpLink}
              target={isMobile ? `_blank` : `_self`}
              data-section="hero-banner"
            >
              {translate.TryForFree}
            </a>
          </Container>
        </div>
        <div className={`satisfied-merchats`}>
          <Container fluid={`lg`}>
            <div
              className={`merchats d-flex flex-column flex-md-row align-items-center align-items-md-center justify-content-center justify-content-md-between py-2 py-md-3 px-4`}
            >
              <div className="text d-flex flex-row flex-md-column align-items-center align-items-md-start my-3 ms-md-4 me-md-3 me-lg-5 pe-md-3 pe-lg-5">
                <div className={`number me-2`}>{merchantNumber}</div>
                <div className={`label`}>{merchantLabel}</div>
              </div>
              <div className={`logos py-3 me-md-4 flex-grow-1`}>
                <Slider {...slidersetting}>
                  {logos.map((logo, index) => (
                    <LazyLoadImage
                      key={`${index}`}
                      src={logo.LogoLink}
                      alt={logo.LogoAlt}
                      className={`mx-2 p-2`}
                      effect={`blur`}
                      width={`110`}
                      height={`54`}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  };

  // Module Outcome
  const Outcome = ({ title, description, outcome }) => {
    return (
      <div className={`outcome`}>
        <Container fluid={`lg`} className={`text-center`}>
          <Row className={`justify-content-center pb-1 pb-md-3`}>
            <Col xs={12}>
              <h2 className={`title`}>{title}</h2>
            </Col>
            <Col xs={12} md={7}>
              <div className={`description mt-2`}>{description}</div>
            </Col>
          </Row>
          <Row className={`outcomes mt-3 mt-md-4`}>
            {outcome.map((item, index) => (
              <Col key={`${index}`} xs={12} md={4} className={`py-4`}>
                <div className="number">{item.Number}</div>
                <div className="label mt-2">{item.Caption}</div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  };

  // Module CaseStudy
  const CaseStudy = ({ data }) => {
    const slidersetting = {
      dots: false,
      centerMode: true,
      infinite: false,
      centerPadding: '120px',
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (oldIndex, newIndex) => {
        oldIndex !== undefined && newIndex !== undefined
          ? onSwipe(oldIndex, newIndex)
          : '';
      },
      responsive: [
        {
          breakpoint: 992,
          settings: {
            centerPadding: '60px',
          },
        },
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrows: false,
            centerMode: false,
          },
        },
      ],
    };

    return (
      <>
        <div className={`casestudies bg-light-green`}>
          <div className={`mx-auto text-center`}>
            <h2 className={`mb-3 mb-md-5`}>{data.Title}</h2>
          </div>
          <ScrollTrigger onEnter={onEnterViewport} onExit={onExitViewport}>
            <Slider {...slidersetting}>
              {data.CaseStudies.data.map((thisCase, index) => {
                let thisCaseDat = thisCase.attributes;

                return (
                  <div key={`${index}`} className={`slider-item px-4`}>
                    <div
                      className={`case align-items-stretch mb-3 mb-md-2 d-flex flex-column flex-md-row`}
                    >
                      <div className={`video px-0 w-100`} onClick={ClickVideo}>
                        <video src={`${thisCaseDat.VideoLink}#t=0.01`} muted />
                        <div className={`play-button`}></div>
                      </div>
                      <div
                        className={`info d-flex flex-column justify-content-center py-4 py-md-5 px-4 px-md-5 flex-grow-1`}
                      >
                        <div className={`category py-2`}>
                          {thisCaseDat.Category}
                        </div>
                        <div className={`title pt-0 pt-md-2 pb-2 pb-md-4`}>
                          {thisCaseDat.Title}
                        </div>
                        {thisCaseDat.Slug && (
                          <a
                            href={`${linkprefix}/use-cases/#${thisCaseDat.Slug}`}
                            className={`link d-flex align-items-center pt-2 pb-4 pb-md-2`}
                          >
                            {data.ViewDetails}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </ScrollTrigger>
        </div>
        <div className={`bg-light-green`}>
          <Container>
            <hr />
          </Container>
        </div>
        <div className={`livenvideo bg-light-green`}>
          <div className={`mx-auto text-center`}>
            <Container fluid>
              <h2 className={`mb-3 mb-md-5`}>{data.LivenVideo}</h2>
              <fw-embed-feed
                channel={data.FWChannel}
                playlist={data.FWPlaylist}
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                title=""
              ></fw-embed-feed>
              <a
                className={`btn btn-green mt-4 mt-md-5 py-4`}
                href={data.JoinWebinarLink}
              >
                {data.JoinWebinar}
              </a>
            </Container>
          </div>
        </div>
      </>
    );
  };

  // Module Features
  const Features = ({
    moduleTitle,
    title,
    description,
    imageLink,
    imageAlt,
    data,
  }) => {
    return (
      <div className={`main-feature pb-2 pb-md-4`}>
        <Container fluid={`lg`}>
          <div className={`mx-auto text-center`}>
            <h2 className={`mb-3 mb-md-5`}>{moduleTitle}</h2>
          </div>
          {data?.map((item, index) => (
            <Row
              key={item?.id}
              className={`align-items-center ${
                index !== data?.length - 1 ? 'mb-3 mb-md-5' : ''
              }`}
            >
              <Col xs={12} md={6}>
                <LazyLoadImage
                  src={item?.ImageLink}
                  alt={item?.ImageAlt}
                  effect={`blur`}
                  width={`576`}
                  height={`521`}
                  className={`rounded-3 overflow-hidden`}
                />
              </Col>
              <Col xs={12} md={6} className={`px-3 px-md-5`}>
                <h3 className={`title mt-3 mt-md-0 mb-3`}>{item?.Title}</h3>
                <div className={`description`}>{item?.Description}</div>
                <Row className={`pt-3 pt-md-4 my-3 my-md-2`}>
                  <Col xs={12} lg={6} className={`text-lg-end`}>
                    <a
                      className={`btn btn-outline py-3 mw-100`}
                      href={item?.FeatureLink}
                    >
                      {translate.LearnMore}
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    );
  };

  // Module OtherFeatures
  const OtherFeatures = ({ data, title }) => {
    return (
      <div className={`other-features pt-4`}>
        <Container fluid={`lg`}>
          <div className={`mx-auto text-start text-md-center`}>
            <h3>{title}</h3>
          </div>
          <Row>
            {data.map((feature, index) => {
              return (
                <Col
                  xs={12}
                  md={6}
                  key={`${index}`}
                  className={`feature mt-3 mt-md-5 mb-4 mb-md-0 py-2`}
                >
                  <LazyLoadImage
                    src={feature.ImageLink}
                    alt={feature.ImageAlt}
                    effect={`blur`}
                    width={`576`}
                    height={`324`}
                  />
                  <div className={`title mt-4 mb-2`}>{feature.Title}</div>
                  <div className={`description`}>{feature.Description}</div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    );
  };

  // Module Resources
  const Resources = ({ moduleTitle, data }) => {
    const slidersetting = {
      dots: false,
      infinite: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: true,
            dots: true,
          },
        },
      ],
    };
    data = data.filter((i) => moment(i.attributes.EndDate).isAfter(moment()));
    return (
      <div className="resources bg-light-green">
        <Container fluid={`lg`}>
          <div className={`mx-auto text-center`}>
            <h2 className={`mb-3 mb-md-4`}>{moduleTitle}</h2>
          </div>
          <Slider {...slidersetting}>
            {data.map((thisEvent, index) => {
              let thisEventDat = thisEvent.attributes;
              let thisEventType = thisEventDat.Type;
              let startDate = thisEventDat.StartDate;
              let endDate = thisEventDat.EndDate;
              let date = '';
              let time = '';
              let type = '';
              if (thisEventType == 'Event') {
                type = translate.LabelEvent;
              } else if (thisEventType == 'Webinar') {
                type = translate.LabelWebinar;
              } else if (thisEventType == 'Promotion') {
                type = translate.LabelPromotion;
              }
              if (moment(startDate).isSame(endDate, 'day')) {
                if (lang == 'zh-Hant' || lang == 'zh-Hans') {
                  date = `${translate.Date}：${moment(startDate)
                    .locale('zh-hk')
                    .format('Mo Do (dddd)')}`;
                } else {
                  date = `${translate.Date}：${moment(startDate)
                    .locale('en-gb')
                    .format('MMMM D (dddd)')}`;
                }
                time = `${translate.Time}：${moment(startDate)
                  .locale('en-gb')
                  .format('h:mmA')} - ${moment(endDate)
                  .locale('en-gb')
                  .format('h:mmA')}`;
              } else {
                if (lang == 'zh-Hant' || lang == 'zh-Hans') {
                  date = `${translate.Date}：${moment(startDate)
                    .locale('zh-hk')
                    .format('Mo Do (dddd)')} - ${moment(endDate)
                    .locale('zh-hk')
                    .format('Mo Do (dddd)')}`;
                } else {
                  date = `${translate.Date}：${moment(startDate)
                    .locale('en-gb')
                    .format('MMMM D (dddd)')} - ${moment(endDate)
                    .locale('en-gb')
                    .format('MMMM D (dddd)')}`;
                }
              }
              return (
                <div
                  key={`${index}`}
                  className={`resource h-100 d-flex flex-column ${thisEventDat.Type}`}
                >
                  <div className={`image`}>
                    <div className={`labels d-flex`}>
                      {thisEventType && (
                        <div
                          className={`label type ${thisEventType} d-flex align-items-center px-2 py-1 mx-1`}
                        >
                          {type}
                        </div>
                      )}
                      {thisEventDat.Language && (
                        <div className={`label language px-2 py-1 mx-1`}>
                          {thisEventDat.Language}
                        </div>
                      )}
                    </div>
                    <LazyLoadImage
                      src={thisEventDat.ImageLink}
                      alt={thisEventDat.ImageAlt}
                      effect={`blur`}
                      width={`100%`}
                      height={`56.75%`}
                    />
                  </div>
                  <div
                    className={`info px-4 py-3 flex-grow-1 d-flex flex-column`}
                  >
                    <div className={`title my-2`}>
                      <a
                        href={
                          thisEventDat.Link
                            ? thisEventDat.Link
                            : linkprefix +
                              '/resource/events/' +
                              thisEventDat.Slug
                        }
                        target={`_blank`}
                      >
                        {thisEventDat.Title}
                      </a>
                    </div>
                    {date && <div className={`date my-1`}>{date}</div>}
                    {time && <div className={`date my-1`}>{time}</div>}
                    <div
                      className={`description`}
                      dangerouslySetInnerHTML={{
                        __html: thisEventDat.ShortDescription,
                      }}
                    />
                    {thisEventDat.ButtonLabel && (
                      <a
                        href={
                          thisEventDat.Link
                            ? thisEventDat.Link
                            : linkprefix +
                              '/resource/events/' +
                              thisEventDat.Slug
                        }
                        target={`_blank`}
                        className={`btn btn-green py-2 mt-auto`}
                      >
                        {thisEventDat.ButtonLabel}
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </Container>
      </div>
    );
  };

  let hpData = '';
  if (homepagedata.data !== undefined) {
    hpData = homepagedata.data.attributes;
  }

  let lData = '';
  if (resourcelistdata.data !== null) {
    lData = resourcelistdata.data.attributes;
  }

  useEffect(() => {
    if (!serverReq) {
      const header = document.querySelector('header');
      const btnGreen = document.querySelector('header form .btn-green');
      const landing = document.querySelector('#landing');

      if (landing && btnGreen) {
        btnGreen.classList.add('d-none');
      }

      const handleScroll = () => {
        const distanceScrolled = document.documentElement.scrollTop;
        if (landing) {
          if (distanceScrolled > 100) {
            header.classList.remove('landing');
            btnGreen.classList.remove('d-none');
          } else {
            header.classList.add('landing');
            btnGreen.classList.add('d-none');
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      const script = document.createElement('script');
      script.src = 'https://asset.fwcdn3.com/js/embed-feed.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  let { country, lang } = getGlobal().linkstate;
  let linkprefix = `/${country}/${lang}`;

  return (
    <PageWrapper pageData={data}>
      <div id={`landing`}>
        {hpData && (
          <HomeBanner
            video={hpData.HeroBanner.VideoLink}
            mobileVideo={hpData.HeroBanner.MobileVideoLink}
            title={hpData.HeroBanner.Title}
            description={hpData.HeroBanner.Description}
            merchantNumber={hpData.HeroBanner.MerchantNumber}
            merchantLabel={hpData.HeroBanner.MerchantLabel}
            logos={hpData.HeroBanner.Logos}
          />
        )}
        <div className="features">
          {hpData && (
            <Features
              moduleTitle={hpData.VideoFeatureModule.ModuleTitle}
              title={hpData.VideoFeatureModule.Title}
              description={hpData.VideoFeatureModule.Description}
              imageLink={hpData.VideoFeatureModule.ImageLink}
              imageAlt={hpData.VideoFeatureModule.ImageAlt}
              data={hpData.VideoFeatureModule.VideoFeatureContents}
            />
          )}
          {hpData && (
            <OtherFeatures
              data={hpData.VideoFeatureModule.OtherFeatures}
              title={hpData.VideoFeatureModule.OtherFeatureTitle}
            />
          )}
        </div>
        {/* {hpData && (
          <Outcome
            title={hpData.BusinessOutcomeModule.Title}
            description={hpData.BusinessOutcomeModule.Intro}
          outcome={hpData.BusinessOutcomeModule.Outcome}
          />
        )} */}
        {hpData && <CaseStudy data={hpData.CaseStudyModule} />}
        {hpData && lData && (
          <>
            <div className={`bg-light-green`}>
              <Container>
                <hr />
              </Container>
            </div>
            <Resources
              moduleTitle={hpData.JoinEventModule.ModuleTitle}
              data={lData.resources.data}
            />
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export async function getStaticPaths() {
  return {
    paths: [
      { params: { region: 'HK', lang: 'zh-Hant' } },
      { params: { region: 'HK', lang: 'en' } },
      // { params: { region: 'HK', lang: 'zh-Hans' } },
      // { params: { region: 'US', lang: 'en' } },
      { params: { region: 'MY', lang: 'en' } },
      // { params: { region: 'MY', lang: 'ms' } },
      { params: { region: 'MY', lang: 'zh-Hans' } },
      // { params: { region: 'MY', lang: 'zh-Hant' } },
      // { params: { region: 'IND', lang: 'en' } },
      // { params: { region: 'IND', lang: 'ina' } },
      // { params: { region: 'JP', lang: 'ja' } },
      // { params: { region: 'JP', lang: 'en' } },
      { params: { region: 'Others', lang: 'en' } },
      // { params: { region: 'Others', lang: 'Thai' } }
    ],
    fallback: false,
  };
}

export async function getStaticProps(context) {
  const langForApi = languageObj[context.params.region][context.params.lang];
  let pageData = await fetchAllData('homepage', langForApi);
  let resData = await fetchAllData('resources-list', langForApi);

  return {
    props: { pageData, resData },
  };
}

export default Homepage;
